import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“What?!”, you say. “GraphQL is a server-side query language. Redux is a client-side state management library. How could one replace the other?” Good question.`}</p>
    <p><em parentName="p">{`Hold onto your butts, because I’m about to answer it.`}</em></p>
    <h3>{`⚛️ Switching to React`}</h3>
    <p>{`First, a little back-story. Back in 2016, the front-end team at `}<a parentName="p" {...{
        "href": "https://www.pathwright.com/"
      }}>{`Pathwright`}</a>{` began switching our client-side code from a Backbone & Marionette stack to React. The declarative model for UI made much more sense than the MVC patterns we’d been dealing with.`}</p>
    <p>{`It was a breath of fresh air and still is to a large degree.`}</p>
    <p>{`Everything was beautiful except for the state management side of our app. We turned to the Flux architecture quickly, and it felt like a major improvement at first. The concepts of circular data flow and single source of truth were philosophically sound and at least saner than the model-centric view binding approach of most MVC libraries.`}</p>
    <p>{`However, as our state management needs grew more complex, it started to feel like more and more layers of indirection. Once you have a handful of stores or branches in your state tree, you end up practically duplicating your server-side business data and relationships on the client.`}</p>
    <p>{`We had these beautifully declarative React components with a data layer that became a rats nest of actions, reducers, async middleware, and de-normed business data/logic.`}</p>
    <p><em parentName="p">{`This all felt very wrong.`}</em></p>
    <h3>{`↪️ Switching to GraphQL`}</h3>
    <p>{`That’s when we tried GraphQL. We began by implementing it on a new dashboard that combined a bunch of different data sources (this would have been a nightmare to do with our RESTfull APIs) and soon fell in love. It was like discovering React for the first time. Enthusiasm was high enough that we ended up getting our newly minted GraphQL server in production in only two weeks!`}</p>
    <p>{`Soon after, we started replacing a bunch of our REST APIs with GraphQL and it continued to be amazing.`}</p>
    <p>{`One of the side effects (sagas?) of this was that our UIs using these new GraphQL endpoints no longer needed stores at all. We started like we normally would with new stores, actions etc., but ended up deleting them because there simply wasn’t anything for them to do.`}</p>
    <h3>{`🤯 Three Startling Realizations`}</h3>
    <p>{`This led to three startling, yet obvious in hind-sight, realizations for us:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Most of our state management code was concerned with merging and mutating data from discrete REST resources into the right shape for our UI (reducers, selectors, actions etc.).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A lot of our most complex state management was trying to manage the asynchronous nature of getting all that data in the right order for a specific route (sagas, middleware, thunks etc.).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Practically everything else, UI state, worked great with plain old React state.`}</p>
      </li>
    </ol>
    <p>{`“Damn”, we said, “damn,” while plunking coins into the swear jar.`}</p>
    <p>{`And then we deleted a `}<em parentName="p">{`lot`}</em>{` of code. It felt good.`}</p>
    <h3>{`So about GraphQL and Redux…`}</h3>
    <p>{`My title was a little mis-leading (made you click?). What we really replaced was our REST API and then found as a result that most of our state management code was `}<em parentName="p">{`no longer necessary`}</em>{`.`}</p>
    <p>{`When the client can control the exact shape of the state it needs from the server and get it all back in a single request, there’s simply not much need for state management libraries.`}</p>
    <p>{`To illustrate the point, let’s pretend our UI is having a conversation with our backend service through the state management library we’re using.`}</p>
    <p>{`Here’s what that might look like:`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/2000/1*Q5OzvFQDJSzk-LpGjYgtHA.png",
        "alt": "Redux + REST left — Apollo + GraphQL right"
      }}></img><em parentName="p">{`Redux + REST left — Apollo + GraphQL right`}</em></p>
    <p>{`So much of the work done by Redux and side-effect management libraries is trying to simplify that left-most conversation above.`}</p>
    <p>{`I would argue that for `}<em parentName="p">{`most`}</em>{` client-side apps, GraphQL can replace the need for Redux entirely.`}</p>
    <p>{`I’m not saying Redux doesn’t serve a purpose. It’s a great library and the state management patterns it’s introduced are going to be around for a long time.`}</p>
    <p>{`Sometimes you don’t have control of your back-end stack and you’re forced to force REST to behave for your client-side UIs. That sucks and Redux really helps in that environment.`}</p>
    <p>{`There are also cases where you’re managing very complex state that needs trackable and consistent control: lower-level things like a client-side cache, offline syncing, etc. Redux is great for these cases. In fact, some popular GraphQL libraries like Apollo can use Redux under the hood as a cache.`}</p>
    <p>{`BUT…`}</p>
    <p><strong parentName="p"><em parentName="strong">{`If you can use GraphQL instead of REST, you should`}</em></strong>{`. Switching will get rid of a huge amount of complexity in your client-side state management and reduce the scope of your client-side code to just how data should render in the UI (which is what it should have been all along).`}</p>
    <p>{`Oh, and you can still use Redux alongside GraphQL, you just won’t need it very much. Plus you may be able to delete half your code which feels awesome.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      